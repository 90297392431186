import picture from './content/HM4A3115.jpg'
import React from 'react';
import { motion } from 'framer-motion';

function About({ aboutRef }) {
    return (
        <>
            {window.innerWidth < 640 ? (
                <>
                <div className="w-full h-[104vh] bg-background z-10 items-center flex flex-col pb-7" ref={aboutRef}>
                        <motion.div className="w-8/12 h-5/6 text-8xl items-center justify-center flex"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.75 }}
                        >
                            <img src={picture} className='scale-90 rounded-xl'></img>
                        </motion.div>
                        <motion.div className="justify-center w-11/12 h-1/2 text-text flex flex-col gap-5 bg-box rounded-2xl shadow-lg shadow-s p-8"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.75 }}
                        >
                            <div className="text-2xl flex justify-center">About Me</div>
                            <div className="text-sm mt-5 flex justify-center text-center">Hi everyone! Thank you for visiting my website! My name is Isa Providence, I graduated from Syracuse University with my B.F.A. in Acting and decided to move across the country to Los Angeles. I’m an actor, singer, strong mover who’s spent most of their life on a stage (cue every “by the time I was 5…” story). I’ve had the privilege of starting my professional career through Syracuse Stage and can not wait for what lies ahead! Other than performing, I also love karaoke, cooking, getting crafty, and cats (the animal, not the musical). I hope you take a look around and stay a while!</div>
                        </motion.div>
                    </div>
                </>
            ) : (
                <>
                    <div className="w-full h-[110vh] bg-background z-10 items-center grid grid-cols-2" ref={aboutRef}>
                        <motion.div className=" w-7/12 h-5/6 ml-32 text-8xl items-center justify-center flex grid-row-1"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.75 }}
                        >
                            <img src={picture} className='scale-110 rounded-xl'></img>
                        </motion.div>
                        <motion.div className={`justify-center ${window.innerHeight < 900 || window.innerWidth < 1335 ? 'h-fit' : 'h-[60%]'} flex flex-col text-center items-center mr-32 bg-box rounded-2xl text-text shadow-lg shadow-s gap-10`}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.75 }}
                        >
                            <div className="text-7xl flex justify-center">About Me</div>
                            <div className="text-2xl flex justify-center text-center">Hi everyone! Thank you for visiting my website! My name is Isa Providence, I graduated from Syracuse University with my B.F.A. in Acting and decided to move across the country to Los Angeles. I’m an actor, singer, strong mover who’s spent most of their life on a stage (cue every “by the time I was 5…” story). I’ve had the privilege of starting my professional career through Syracuse Stage and can not wait for what lies ahead! Other than performing, I also love karaoke, cooking, getting crafty, and cats (the animal, not the musical). I hope you take a look around and stay a while!</div>
                        </motion.div>
                    </div>
                </>
            )}
        </>
    )
}

export default About;