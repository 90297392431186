import React from "react";
import { motion } from "framer-motion";
import Button from "./ReelsButton";

function Reel({ reelRef }) {
    return (
        <div className="w-screen h-[150vh] pb-3 bg-background z-10 grid sm:grid-rows-6 sm:grid-cols-1 md:grid-rows-4 text-text md:grid-cols-2 lg:grid-rows-4 lg:grid-cols-2 pt-16" ref={reelRef}>
            <div className="start-row-1 start-col-1 flex justify-center items-center">
                <motion.div className="w-full h-full flex justify-center items-center"
                    initial={{ x: -200 }}
                    whileInView={{ x: 0 }}
                    transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                >
                    <iframe className="w-3/4 h-5/6 rounded-xl" src="https://www.youtube.com/embed/BKOlc0WrLU8?si=Ge9J2zLo7dZbonk3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </motion.div>
            </div>
            <div className="start-row-1 grid-col-2 flex justify-center items-center">
                <motion.div className="w-full h-full flex justify-center items-center"
                    initial={{ x: 200 }}
                    whileInView={{ x: 0 }}
                    transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                >
                    <div className={`h-5/6 w-3/4 bg-box border-xl ${window.innerWidth < 640 ? "text-3xl h-full w-3/4" : "text-3xl gap-10 h-5/6 w-3/4"} flex-col text-center shadow-lg shadow-s  rounded-lg flex items-center justify-center`}>
                        "Proof" by David Auburn
                        <div className={window.innerWidth < 640 ? "text-xl" : "text-xl"}>Syracuse Showcase</div>
                    </div>
                </motion.div>
            </div>
            {window.innerWidth < 640 ? (
                <>
                    <div className="start-row-2 start-col-2 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: -200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <iframe className="w-3/4 h-5/6 rounded-xl" src="https://www.youtube.com/embed/UmlUocyYVwc?si=D8VZYj4QrTqbWHpt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </motion.div>
                    </div>
                    <div className="start-row-2 start-col-1 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: 200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <div className={`h-5/6 w-3/4 bg-box border-xl ${window.innerWidth < 640 ? "text-3xl h-full w-3/4" : "text-7xl gap-10 h-5/6 w-3/4"} flex-col text-center shadow-lg shadow-s  rounded-lg flex items-center justify-center`}>
                                “I’ll Be Here” from Ordinary Days
                                <div className={window.innerWidth < 640 ? "text-xl" : "text-5xl"}>Dont Tell Mama NYC</div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="start-row-3 start-col-2 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: -200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <iframe className="w-3/4 h-5/6 rounded-xl" src="https://www.youtube.com/embed/vladdQcG7N0?si=JT8VuIUWRm6LypNg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </motion.div>
                    </div>
                    <div className="start-row-3 start-col-1 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: 200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <div className={`h-5/6 w-3/4 bg-box border-xl ${window.innerWidth < 640 ? "text-3xl h-full w-3/4" : "text-3xl gap-10 h-5/6 w-3/4"} flex-col text-center shadow-lg shadow-s  rounded-lg flex items-center justify-center`}>
                                Original Scene
                                <div className={window.innerWidth < 640 ? "text-xl" : "text-xl"}>Syracuse Showcase</div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="start-row-4 start-col-1 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: 200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <iframe className="w-3/4 h-5/6 rounded-xl" src="https://www.youtube.com/embed/kxFaX1MbeRs?si=bd0s19CXlqvEmqwt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </motion.div>
                    </div>
                    <div className="start-row-4 start-col-2 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: -200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <div className={`h-5/6 w-3/4 bg-box border-xl ${window.innerWidth < 640 ? "text-3xl h-full w-3/4" : "text-3xl gap-10 h-5/6 w-3/4"} flex-col text-center shadow-lg shadow-s  rounded-lg flex items-center justify-center`}>
                                “If I Had You” from I Could Use A Drink
                                <div className={window.innerWidth < 640 ? "text-lg" : "text-xl"}>Dont Tell Mama NYC</div>
                            </div>
                        </motion.div>
                    </div>
                </>
            ) : (
                <>
                    <div className="start-row-2 start-col-1 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: -200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <div className="h-5/6 w-3/4 bg-box border-xl text-3xl flex-col text-center shadow-lg shadow-s gap-10 rounded-lg flex items-center justify-center">
                                “I’ll Be Here” from Ordinary Days
                                <div className="text-xl">Dont Tell Mama NYC</div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="start-row-2 start-col-2 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: 200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <iframe className="w-3/4 h-5/6 rounded-xl" src="https://www.youtube.com/embed/UmlUocyYVwc?si=D8VZYj4QrTqbWHpt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </motion.div>
                    </div>
                    <div className="start-row-3 start-col-2 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: -200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <iframe className="w-3/4 h-5/6 rounded-xl" src="https://www.youtube.com/embed/vladdQcG7N0?si=JT8VuIUWRm6LypNg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </motion.div>
                    </div>
                    <div className="start-row-3 start-col-1 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: 200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <div className={`h-5/6 w-3/4 bg-box border-xl ${window.innerWidth < 640 ? "text-3xl h-full w-3/4" : "text-3xl gap-10 h-5/6 w-3/4"} flex-col text-center shadow-lg shadow-s  rounded-lg flex items-center justify-center`}>
                                Original Scene
                                <div className={window.innerWidth < 640 ? "text-xl" : "text-xl"}>Syracuse Showcase</div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="start-row-4 start-col-2 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: -200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <div className={`h-5/6 w-3/4 bg-box border-xl ${window.innerWidth < 640 ? "text-3xl h-full w-3/4" : "text-3xl gap-10 h-5/6 w-3/4"} flex-col text-center shadow-lg shadow-s  rounded-lg flex items-center justify-center`}>
                                “If I Had You” from I Could Use A Drink
                                <div className={window.innerWidth < 640 ? "text-xl" : "text-xl"}>Dont Tell Mama NYC</div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="start-row-4 start-col-1 flex justify-center items-center">
                        <motion.div className="w-full h-full flex justify-center items-center"
                            initial={{ x: 200 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.5, type: "spring", bounce: 0.2, delay: 0.05 }}
                        >
                            <iframe className="w-3/4 h-5/6 rounded-xl" src="https://www.youtube.com/embed/kxFaX1MbeRs?si=bd0s19CXlqvEmqwt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </motion.div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Reel;